<template>
  <div>
    <UIExit label="Exit Fee Documentation" :exit-url="defaultRedirect" />
    <TitleWrapper
      :filter-option="false"
      title="FEE_MANAGER_DOCUMENTATION"
      tooltip-title="FEE_MANAGER_DOCUMENTATION"
      :display-breadcrumb="true"
    ></TitleWrapper>
    <div class="bg-white">
      <!--  Section -->
      <documentation :document-list="questionList" answer-page="fee-question" />
      <!-- Footer -->
    </div>
  </div>
</template>

<script>
import documentation from '@src/router/layouts/app-document/documentation.vue'
import TitleWrapper from '@/src/components/TitleWrapper.vue'
import UIExit from '@/src/components/UiElements/UIExit.vue'
import ROUTE from '@/src/constants/route-constants.js'
import FEE_DATA from '@/src/router/layouts/app-document/fee-documentation/fee-manager.json'

export default {
  components: {
    documentation,
    TitleWrapper,
    UIExit,
  },
  page: {
    title: 'Fee Documentation',
    meta: [{ name: 'fee manager documentation', content: 'fee manager documentation' }],
  },
  data() {
    return {
      landingPage: 'landingPage',
      defaultRedirect: ROUTE.QUICK_ACTIONS.name,
      questionList: FEE_DATA.questionList,
    }
  },
}
</script>
